//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
    name: 'locale-changer',
    methods: {            
        changeLang() {
            //https://vee-validate.logaretm.com/v2/guide/localization.html#localization-api
            this.$i18n.locale = this.$i18n.locale == "ar" ? "en" : "ar";
            localStorage.lang = this.$i18n.locale;
            this.langPathImg = localStorage.lang == "en" ? "/assets/images/ar.png" : "/assets/images/en.png"
            this.SetCLang();

            slidersFix();
            //location.reload();

        },
        SetCLang: function () {
            if (localStorage.lang == "en") {
                document.getElementsByTagName("html")[0].setAttribute("dir", "ltr")
                document.getElementsByTagName("html")[0].setAttribute("lang", "en")
                document.title = "SASCO"
            }
            else {
                document.getElementsByTagName("html")[0].setAttribute("dir", "rtl")
                document.getElementsByTagName("html")[0].setAttribute("lang", "ar")
                document.title = "ساسكو"
            }
        },
    },
    mounted() {
        let self = this;
        this.SetCLang();
    },
}
