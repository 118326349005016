//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SwitchLang from '@/components/shared/SwitchLang.vue';
import SearchContent from '@/components/shared/SearchContent.vue';
//import moment from 'moment'
import moment from 'moment-hijri'
export default {
    name: "Header",
    components: {
        SwitchLang,
        SearchContent
    },
    data() {
        let self = this;
        return {
            cGorgDate: moment().locale(self.$i18n.locale).format('DD MMMM yyyy'),
            CHijriDate: moment().locale(self.$i18n.locale == "ar" ? "ar-SA" : "en").format('dddd iD iMMMM iYYYY'),
            TweetURL: process.env.VUE_APP_TwiterPage
        }
    },
    watch: {
        '$i18n.locale': function () {
            let self = this;
            self.cGorgDate = moment().locale(self.$i18n.locale).format('DD MMMM yyyy');
            self.CHijriDate = moment().locale(self.$i18n.locale == "ar" ? "ar-SA" : "en").format('dddd iD iMMMM iYYYY');
        },

        $route() {
            let header = document.getElementsByClassName("ndf-header");
            header[0].classList.remove("active");
        }
    },
    methods: {
        twitterToggle: function () {
            console.log();
        }
    }
};
