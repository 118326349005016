import Vue from 'vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';
import VueAxios from 'vue-axios';
import i18n from './i18n'
import vuetify from '@/assets/plugins/vuetify'

import VeeValidate from "vee-validate";
import Snotify, { SnotifyPosition } from 'vue-snotify'
//import LoadScript from 'vue-plugin-load-script';//https://www.npmjs.com/package/vue-plugin-load-script
//import * as VueGoogleMaps from 'vue2-google-maps'

import '../public/assets/css/bootstrap.min.css';
import '../public/assets/css/style.css';

VeeValidate.Validator.extend('mobileReg', {
    getMessage: field => `The Mobile must contain at Only Number`,
    validate: value => {
        var strongRegex = new RegExp("^[0-9]{0,10}$");
        return strongRegex.test(value);
    }
});
const options = {
    toast: {
        position: SnotifyPosition.rightTop,
        closeOnClick: true
    }
}
Vue.use(Snotify, options);
Vue.config.productionTip = true;
Vue.use(VeeValidate);
Vue.use(
    VueAxios,
    axios,
);
//Vue.use(LoadScript);

//Vue.use(VueGoogleMaps, {
//    load: {
//        key: 'AIzaSyBkdsK7PWcojsO-o_q2tmFOLBfPGL8k8Vg',
//        libraries: 'places',
//    }
//});


export const EventBus = new Vue();

new Vue({
    router,
    i18n,
    vuetify,
    render: h => h(App)
}).$mount('#app');


axios.interceptors.request.use(function (req) {

    req.headers = {
        'Lang': i18n.locale
    }
    return req;
});