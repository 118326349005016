import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)



export default new Router({
    linkActiveClass: "active",
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0, behavior: 'smooth' }
    },
    routes: [
        {
            path: '/',
            name: 'Home',
            component: () => import('@/views/Home.vue'),
        },
        {
            path: '/contact-us',
            name: 'ContactUs',
            component: () => import('@/views/ContactUs.vue'),
        },
        {
            path: '/Career',
            name: 'Career',
            component: () => import('@/views/Career.vue'),
        },
        {
            path: '/News',
            name: 'News',
            component: () => import('@/views/AllNews.vue'),
        }, 
        {
            path: '/advertisement',
            name: 'advertisement',
            component: () => import('@/views/AllAdvertisement.vue'),
        },
        {
            path: '/NewsDetails/:id',
            name: 'NewsDetails',
            component: () => import('@/views/NewsDetails.vue'),
        },
        {
            path: '/AdvertisementDetails/:id',
            name: 'AdvertisementDetails',
            component: () => import('@/views/AdvertisementDetails.vue'),
        },
        {
            path: '/Multimedia',
            name: 'Multimedia',
            component: () => import('@/views/Multimedia/Multimedia.vue'),
        },
        {
            path: '/MultimediaDetails/:id',
            name: 'MultimediaDetails',
            component: () => import('@/views/Multimedia/MultimediaDetails.vue'),
        },
        {
            path: '/FinancialStatement',
            name: 'FinancialStatement',
            component: () => import('@/views/FinancialStatement/FinancialStatement.vue'),
        }
        ,
        {
            path: '/SharePriceAlert',
            name: 'SharePriceAlert',
            component: () => import('@/views/SharePriceAlert/SharePriceAlert.vue'),
        },
        {
            path: '/AnnualReport',
            name: 'AnnualReport',
            component: () => import('@/views/AnnualReport/AnnualReport.vue'),
        },
        {
            path: '/Statistics',
            name: 'Statistics',
            component: () => import('@/views/AllStatistics.vue'),
        },

        {
            path: '/BusinessCompany/:id',
            name: 'BusinessCompanyDetails',
            component: () => import('@/views/BusinessCompanyDetails.vue'),
        },

        {
            path: '/BusinessCompany',
            name: 'BusinessCompany',
            component: () => import('@/views/BusinessCompany.vue'),
        },
        {
            path: '/SearchContentResult',
            name: 'SearchContentResult',
            component: () => import('@/views/SearchContent/SearchContentResult.vue'),
        },

        // Start SASCO Activities
        {
            path: '/sasco-operations-sector',
            name: 'SascoOperationsSector',
            component: () => import('@/views/Activities/SascoOperationsSector.vue'),
        },
        {
            path: '/sata',
            name: 'Sata',
            component: () => import('@/views/Activities/Sata.vue'),
        },
        {
            path: '/ostool-alnaqil-co',
            name: 'OstoolAlNaqilCo',
            component: () => import('@/views/Activities/OstoolAlNaqilCo.vue'),
        },
        {
            path: '/sasco-palm',
            name: 'SascoPalm',
            component: () => import('@/views/Activities/SascoPalm.vue'),
        },
        {
            path: '/investments-and-equipment-company',
            name: 'InvestmentsAndEquipmentCompany',
            component: () => import('@/views/Activities/InvestmentsAndEquipmentCompany.vue'),
        },
        {
            path: '/al-nakhla-al-oula-contracting-co',
            name: 'AlNakhlaAlOulaContractingCo',
            component: () => import('@/views/Activities/AlNakhlaAlOulaContractingCo.vue'),
        },
        {
            path: '/sasco-al-waha-co',
            name: 'SascoAlWahaCo',
            component: () => import('@/views/Activities/SascoAlWahaCo.vue'),
        },
        {
            path: '/sasco-franchise-company',
            name: 'SascoFranchiseCompany',
            component: () => import('@/views/Activities/SascoFranchiseCompany.vue'),
        },
        {
            path: '/amlak-nakhla-real-estate-company',
            name: 'AmlakNakhlaRealEstateCompany',
            component: () => import('@/views/Activities/AmlakNakhlaRealEstateCompany.vue'),
        },
        {
            path: '/wajihat-al-shamal-real-estate-company',
            name: 'WajihatAlShamalRealEstateCompany',
            component: () => import('@/views/Activities/WajihatAlShamalRealEstateCompany.vue'),
        },
        // End SASCO Activities

        // Start About Us
        {
            path: '/about-us',
            name: 'AboutUs',
            component: () => import('@/views/AboutUs/AboutUs.vue'),
        },
        {
            path: '/our-values',
            name: 'OurValues',
            component: () => import('@/views/AboutUs/OurValues.vue'),
        },
        {
            path: '/our-history',
            name: 'OurHistory',
            component: () => import('@/views/AboutUs/OurHistory.vue'),
        },
        {
            path: '/corporate-governance',
            name: 'CorporateGovernance',
            component: () => import('@/views/AboutUs/CorporateGovernance.vue'),
        },
        {
            path: '/chairman-message',
            name: 'ChairmanMessage',
            component: () => import('@/views/AboutUs/ChairmanMessage.vue'),
        },
        {
            path: '/Privacy',
            name: 'Privacy',
            component: () => import('@/views/AboutUs/Privacy.vue'),
        },
        {
            path: '/use-terms',
            name: 'UseTerms',
            component: () => import('@/views/AboutUs/UseTerms.vue'),
        },
        {
            path: '/ceo-message',
            name: 'CEOMessage',
            component: () => import('@/views/AboutUs/CEOMessage.vue'),
        },
        {
            path: '/board-of-directors',
            name: 'BoardOfDirectors',
            component: () => import('@/views/AboutUs/BoardOfDirectors.vue'),
        },
        // End About Us

        // Start Our Services
        {
            path: '/our-services',
            name: 'OurServices',
            component: () => import('@/views/OurServices/OurServices.vue'),
        },
        {
            path: '/control-program',
            name: 'ControlProgram',
            component: () => import('@/views/OurServices/ControlProgram.vue'),
        },
        {
            path: '/FixedStations',
            name: 'FixedStations',
            component: () => import('@/views/OurServices/FixedStations.vue'),
        }, 
        {
            path: '/franchise-program',
            name: 'FranchiseProgram',
            component: () => import('@/views/OurServices/FranchiseProgram.vue'),
        },
        {
            path: '/SocialResponsibility',
            name: 'SocialResponsibility',
            component: () => import('@/views/SocialResponsibility.vue'),
        },
        {
            path: '/Presentation',
            name: 'Presentation',
            component: () => import('@/views/Presentation/Presentation.vue'),
        },
        {
            path: '/QuarterlyKeyFigures',
            name: 'QuarterlyKeyFigures',
            component: () => import('@/views/QuarterlyKeyFigures/QuarterlyKeyFigures.vue'),
        },
        {
            path: '/AnnuallyKeyFigures',
            name: 'AnnuallyKeyFigures',
            component: () => import('@/views/AnnuallyKeyFigures/AnnuallyKeyFigures.vue'),
        },
        // End Our Services FranchiseApplication
        {
            path: '/InvestmentCertificateRequest',
            name: 'InvestmentCertificateRequest',
            component: () => import('@/views/InvestmentCertificateRequest.vue'),
        },
         {
             path: '/InvestmentStimulus',
             name: 'InvestmentStimulus',
             component: () => import('@/views/InvestmentStimulus/InvestmentStimulus.vue'),
        }, 
         {
             path: '/QuarterlyKeyFigures1',
             name: 'QuarterlyKeyFigures1',
             component: () => import('@/views/QuarterlyKeyFigures1.vue'),
        }, 
        {
            path: '/InvestmentCalculator',
            name: 'InvestmentCalculator',
            component: () => import('@/views/InvestmentCalculator/InvestmentCalculator.vue'),
        }, 
        {
            path: '/SharePriceInquiry',
            name: 'SharePriceInquiry',
            component: () => import('@/views/SharePriceInquiry/SharePriceInquiry.vue'),
        },
        //{
        //    path: '/SharesInvested',
        //    name: 'SharesInvested',
        //    component: () => import('@/views/components/SharesInvested/SharesInvested.vue'),
        //},

          {
              path: '/FranchiseApplication',
              name: 'FranchiseApplication',
              component: () => import('@/views/FranchiseApplication/FranchiseApplication.vue'),
        },{
              path: '/ShareSeriesMonitor',
              name: 'ShareSeriesMonitor',
              component: () => import('@/views/ShareSeriesMonitor/ShareSeriesMonitor.vue'),
        },
    ],
    mode: 'history',

})
